<template>
    <div class="stu-info-form common-form">
        <div
            class="form-div"
            v-loading="loading"
        >
            <el-form
                v-if="formShow"
                ref="form"
                label-position="right"
                label-width="110px"
                size="medium"
                :disabled="mode == 'view'"
            >
                <template v-for="(item, index) in drawList">
                    <!-- 表单组 -->
                    <el-col
                        v-if="item.type == 'compsGroup'"
                        :key="index"
                        v-show="item.show"
                        class="comps-group-div"
                        :sm="24"
                        :md="24"
                    >
                        <div
                            v-if="mode != 'view'"
                            align="right"
                        >
                            <el-button
                                type="primary"
                                icon="el-icon-minus"
                                circle
                                @click="compsGroupDelGroup(item)"
                            ></el-button>
                            <el-button
                                type="primary"
                                icon="el-icon-plus"
                                circle
                                @click="compsGroupAddGroup(item)"
                            ></el-button>
                        </div>
                        <template v-for="(item2, index2) in item.list">
                            <CompsGroup
                                :key="item.field + index2"
                                :conf="item"
                                :draw-list="item2"
                                @chooseValue="handleChooseValue"
                                @explainLink="handlerExplainLink"
                            />
                        </template>
                    </el-col>
                    <!-- 正常组件 -->
                    <el-col
                        v-else
                        :key="index"
                        v-show="item.show"
                        class="drawing-item"
                        :ref="item.field"
                        :sm="24"
                        :md="item.span || 24"
                    >
                        <UploadImg
                            v-if="item.type == 'upload'"
                            :conf="item"
                        />
                        <UploadImgMulti
                            v-else-if="item.type == 'miupload'"
                            :conf="item"
                        />
                        <UploadFile
                            v-else-if="item.type == 'fileload'"
                            :conf="item"
                        />
                        <AddressComp
                            v-else-if="item.type == 'address'"
                            :conf="item"
                        />
                        <CompItem
                            v-else
                            :conf="item"
                            @chooseValue="handleChooseValue"
                            @explainLink="handlerExplainLink"
                        />
                    </el-col>
                </template>
            </el-form>
        </div>

        <div
            v-if="!loading"
            class="bottom-btn"
        >
            <el-button
                type="primary"
                @click="submit"
                >保存</el-button
            >
        </div>

        <!-- iframe弹窗 -->
        <DialogWrapper
            :dialog-obj="iframeDialogObj"
            @handleClose="iframeDialogObj.dialogVisible = false"
        >
            <iframe
                :src="iframeDialogObj.iframeSrc"
                width="100%"
                height="420px"
                scrolling="auto"
                frameborder="0"
            ></iframe>
        </DialogWrapper>
    </div>
</template>

<script>
import {
    comps,
    keysBoolean,
    keysObjArr,
    compsStoreVal,
    compsValObjArr,
    compsChangeOptions,
} from "./comps.js";
import CompItem from "./CompItem.vue";
import UploadImg from "./UploadImg.vue"; // 单图片上传
import UploadImgMulti from "./UploadImgMulti.vue"; // 多图片上传
import UploadFile from "./UploadFile.vue"; // 附件上传
import AddressComp from "./AddressComp.vue"; // 地址选择
import CompsGroup from "./CompsGroup.vue";
import {DialogWrapper} from "common-local";

export default {
    name: "StuInfoForm",
    components: {
        CompItem,
        UploadImg,
        UploadImgMulti,
        UploadFile,
        AddressComp,
        CompsGroup,
        DialogWrapper,
    },
    props: [
        "mode",
        "schoolId",
        "stuBasicInfo",
        "templateInfo",
        "compsInfo",
        "valueInfo",
    ],
    data() {
        return {
            loading: false,
            formShow: false,
            data: [],
            drawList: [],
            iframeDialogObj: {
                title: "",
                dialogVisible: false,
                width: "90%",
                top: "5vh",
                iframeSrc: "",
            },
        };
    },
    filters: {},
    computed: {},
    watch: {
        drawList: {
            handler(newVal) {
                this.$eventDispatch("getFormRules", newVal);
            },
            deep: true,
        },
    },
    created() {
        this.init();
    },
    mounted() {},
    methods: {
        // 简单深拷贝
        cloneDeep(data) {
            return JSON.parse(JSON.stringify(data));
        },
        // 接口请求结果处理
        handleRes(res, fn) {
            if (res.data.code === "200") {
                fn();
            } else if (res.data && res.data.msg) {
                this.$message.error(res.data.msg);
            }
        },
        init() {
            this.loading = true;
            this.formShow = false;
            this.getData();
        },
        // 获取用户填写的数据
        getData() {
            if (this.templateInfo.updateType == "2") {
                // 更新型
                this._fet("/school/schoolArchivesData/listByCondition", {
                    archivesId: this.templateInfo.id,
                    peopleId: this.stuBasicInfo.id,
                    schoolId: this.schoolId,
                }).then((res) => {
                    console.log("[获取填写数据]接口返回：", res);
                    this.handleRes(res, () => {
                        this.data = res.data.data;
                        this.getFormComps();
                    });
                });
            } else if (this.templateInfo.updateType == "1") {
                // 累加型
                this.data = this.valueInfo;
                this.getFormComps();
            }
        },
        // 获取模板组件
        getFormComps() {
            if (this.templateInfo.updateType == "2") {
                // 更新型
                this._fet("/school/schoolArchivesDesign/listByCondition", {
                    archivesTypeId: this.templateInfo.id,
                }).then((res) => {
                    this.handleRes(res, () => {
                        let allCompsList = res.data.data.sort((a, b) => {
                            return a.sort - b.sort;
                        }); // 根据sort升序
                        this.handleCompsData(allCompsList);
                        this.formShow = true;
                        this.loading = false;
                    });
                });
            } else if (this.templateInfo.updateType == "1") {
                // 累加型
                let allCompsList = this.cloneDeep(this.compsInfo);
                this.handleCompsData(allCompsList);
                this.formShow = true;
                this.loading = false;
            }
        },
        // 处理表单组件及用户填写数据
        handleCompsData(allCompsList) {
            for (let comp of allCompsList) {
                // 处理数据格式
                comp = this.handleKeyVal(comp);
                // console.log(comp)

                // 添加show字段
                if (comp.show !== false) {
                    comp.show = true;
                }
                // 添加value字段
                if (compsStoreVal.includes(comp.type)) {
                    this.$set(comp, "value", this.emptyVal(comp));
                }
                // 给value赋默认值
                if (Object.keys(comp).includes("defaultValue")) {
                    comp.value = this.cloneDeep(comp.defaultValue);
                }
                // 单行文本校验规则为证件号时给documentValue赋值
                if (comp.type == "input" && comp.rules == "document") {
                    if (comp.documentDefaultValue) {
                        this.$set(
                            comp,
                            "documentValue",
                            comp.documentDefaultValue,
                        );
                    } else if (comp.documentOptions[0]?.value) {
                        this.$set(
                            comp,
                            "documentValue",
                            comp.documentOptions[0].value,
                        );
                    } else {
                        this.$set(comp, "documentValue", "");
                    }
                }

                // 给非表单组子组件的组件添加需要的字段并赋值
                if (!comp.compsGroupId) {
                    let valueObj = this.data.find((e) => e.field == comp.field);
                    this.handleCompSetFieldVal(comp, valueObj);
                }
            }
            // 处理表单组组件渲染数据及用户填写数据
            for (let comp of allCompsList) {
                // 将表单组子组件放入对应表单组中
                if (comp.type == "compsGroup") {
                    comp.subComps = allCompsList.filter(
                        (e) => e.compsGroupId == comp.field,
                    );
                    comp.list = [];
                    let subCompsData = this.data.filter(
                        (e) => e.compsGroupId == comp.field,
                    );
                    if (subCompsData.length > 0) {
                        let subCompsDataGroupIds = [
                            ...new Set(subCompsData.map((e) => e.groupId)),
                        ];
                        // console.log("[渲染表单组]subCompsDataGroupIds:", subCompsDataGroupIds)
                        for (let groupId of subCompsDataGroupIds) {
                            let subComps = this.cloneDeep(comp.subComps);
                            for (let subComp of subComps) {
                                // console.log("[渲染表单组]subComp:", subComp)
                                // 给表单组子组件添加需要的字段并赋值
                                let valueObj = subCompsData.find(
                                    (e) =>
                                        e.field == subComp.field &&
                                        e.groupId == groupId,
                                );
                                this.handleCompSetFieldVal(subComp, valueObj);
                            }
                            comp.list.push(subComps);
                        }
                    } else {
                        comp.list.push(this.cloneDeep(comp.subComps));
                    }
                }
            }
            // console.log("[获取模板表单内容]allCompsList", allCompsList)
            let drawList = allCompsList.filter((e) => !e.compsGroupId); // 过滤表单组子组件
            this.drawList = drawList;
            // 处理显示与隐藏
            for (let comp of this.drawList) {
                if (comp.relatedComps?.length > 0) {
                    this.handleChooseValue(comp, comp.value);
                }
                if (comp.type == "compsGroup") {
                    this.handleSubCompsShowState(comp);
                }
            }
            if (this.mode == "view") {
                this.desensitization(this.drawList);
            }

            console.log("[获取模板组件]this.drawList", this.drawList);
            this.$forceUpdate();
        },
        desensitization(drawList) {
            drawList.forEach((item) => {
                if (item.rules == "phone" && item.value) {
                    item.value =
                        item.value.slice(0, 3) +
                        "*****" +
                        item.value.slice(item.value.length - 3);
                }
                if (item.documentDefaultValue == "idCardNo" && item.value) {
                    item.value =
                        item.value.slice(0, 3) +
                        "***********" +
                        item.value.slice(item.value.length - 4);
                }
            });
        },
        // 处理字段及值
        handleKeyVal(obj) {
            // console.log(JSON.stringify([]) + 1, JSON.stringify({}) + 2, JSON.parse('true'))
            let keys = Object.keys(obj);
            // 将丢失的字段加回来
            let oriCompData = comps.find((e) => e.type == obj.type);
            if (oriCompData) {
                for (let key in oriCompData) {
                    if (!keys.includes(key)) {
                        obj[key] = oriCompData[key];
                    }
                }
            }
            // 将01转回Boolean
            for (let key of keysBoolean) {
                if (
                    keys.includes(key) &&
                    obj[key] !== true &&
                    obj[key] !== false
                ) {
                    obj[key] = obj[key] === 1;
                    // console.log(key, obj[key])
                }
            }
            // 将JSON字符串转回对象数组
            for (let key of keysObjArr) {
                if (keys.includes(key)) {
                    obj[key] = JSON.parse(obj[key]);
                }
            }
            // console.log(obj)
            return obj;
        },
        // 给组件添加需要的字段并赋值
        handleCompSetFieldVal(comp, valueObj) {
            // 添加groupId
            this.$set(comp, "groupId", valueObj?.groupId);
            // 添加valueId
            this.$set(comp, "valueId", valueObj?.id);
            // 给value赋上用户填写的值
            let keys = Object.keys(comp);
            if (keys.includes("value")) {
                let value = valueObj?.assemblyValue;
                if (value) {
                    if (compsValObjArr.includes(comp.type)) {
                        if (comp.type != "select") {
                            value = JSON.parse(value);
                        } else {
                            if (comp.multiple) {
                                value = value.includes("[")
                                    ? JSON.parse(value)
                                    : [value];
                            } else {
                                value = value.includes("[")
                                    ? JSON.parse(value)[0]
                                    : value;
                            }
                        }
                    }
                    if (comp.type === "date") {
                        // 根据最新的模板格式重置数据格式
                        const regex = /[a-zA-Z0-9]/;
                        let separatorNew = {};
                        // 2024/10/30
                        comp.format.split("").forEach((item,index) => {
                            if(!regex.test(item)) {
                                console.log(separatorNew, item, index)
                                separatorNew[index] = item;
                            }  
                        });
                        let valueArr = value.split('');
                        // 分隔符的数量，/或者-或者年月日的个数
                        let signCount = valueArr.filter(item => !regex.test(item)).length;
                        valueArr.forEach((item,index) => {
                            if(!regex.test(item)) {
                                value = value.replace(item,separatorNew[index]);
                            }
                            // 如果是/-转换为年月日的话，需要再最后追加日

                        })
                        if(signCount < Object.keys(separatorNew).length) {
                            value = value + separatorNew[Object.keys(separatorNew)[Object.keys(separatorNew).length-1]];
                        } else if(signCount > Object.keys(separatorNew).length) {
                            value = value.slice(0, value.length - 1);
                        }
                        console.log(111,value,separatorNew)
                    }
                    comp.value = value;
                }
                // 如果是可修改选项且有值的组件，检查value是不是在选项里
                if (
                    compsChangeOptions.includes(comp.type) &&
                    keys.includes("options")
                ) {
                    let optionsVals = [];
                    if (comp.options.length > 0) {
                        optionsVals = comp.options.map((e) => e.value);
                    }
                    if (compsValObjArr.includes(comp.type)) {
                        if (comp.type == "select" && !comp.multiple) {
                            if (!optionsVals.includes(comp.value)) {
                                comp.value = "";
                            }
                        } else {
                            comp.value = comp.value.filter((e) =>
                                optionsVals.includes(e),
                            );
                        }
                    } else if (!optionsVals.includes(comp.value)) {
                        comp.value = "";
                    }
                }
            }
            // 单行文本校验规则为证件号时给documentValue赋上用户选中的值
            if (comp.type == "input" && comp.rules == "document") {
                let documentValue = valueObj?.supplementValue;
                if (documentValue) {
                    comp.documentValue = documentValue;
                }
            }
        },
        // 处理表单组子组件显示与隐藏
        handleSubCompsShowState(comp) {
            for (let list of comp.list) {
                for (let subComp of list) {
                    if (subComp.relatedComps?.length > 0) {
                        this.handleChooseValue(subComp, subComp.value, list);
                    }
                }
            }
        },
        handleChooseValue(comp, val, list) {
            console.log("[handleChooseValue]", comp, val);
            if (["select", "radio", "checkbox"].includes(comp.type)) {
                // 下拉选择、单选框组、多选框组
                if (comp.relatedComps.length > 0) {
                    let selVal = [].concat(val);
                    console.log(
                        "[handleChooseValue]selVal:",
                        selVal,
                        "relatedComps:",
                        comp.relatedComps,
                    );
                    // 要显示的关联组件
                    let showRelatedComps = comp.relatedComps
                        .filter((e) => selVal.includes(e.opt))
                        ?.map((e) => e.field);
                    // 要隐藏的关联组件，要隐藏的要过滤掉要显示的，因为多选且多个选项关联同一个组件时，只要有一个选项选中了，那个被关联组件就要显示出来
                    let hideRelatedComps = comp.relatedComps
                        .filter((e) => !showRelatedComps.includes(e.field))
                        ?.filter((e) => !selVal.includes(e.opt))
                        ?.map((e) => e.field);
                    let compsList = list ?? this.drawList;
                    // if(comp.compsGroupId) { // 是表单组子组件
                    //     compsList = this.drawList.find(e => e.field == comp.compsGroupId)?.subComps
                    // }
                    console.log(
                        "[handleChooseValue]showRelatedComps:",
                        showRelatedComps,
                        "hideRelatedComps:",
                        hideRelatedComps,
                        "compsList:",
                        compsList,
                    );
                    if (showRelatedComps.length > 0 && compsList?.length > 0) {
                        for (let field of showRelatedComps) {
                            let showRelatedComp = compsList.find(
                                (e) => e.field == field,
                            );
                            if (showRelatedComp) {
                                showRelatedComp.show = true;
                            }
                        }
                    }
                    if (hideRelatedComps.length > 0 && compsList?.length > 0) {
                        for (let field of hideRelatedComps) {
                            let hideRelatedComp = compsList.find(
                                (e) => e.field == field,
                            );
                            if (hideRelatedComp) {
                                hideRelatedComp.show = false;
                                // hideRelatedComp.value = this.emptyVal(hideRelatedComp) // 隐藏的组件将值清空
                            }
                        }
                    }
                }
                // 检查是否有已隐藏但不被任何组件关联的组件，将其显示出来
                // let hideComps = this.drawList.filter(e => e.show === false)
                // console.log("[handleChooseValue]hideComps.filed:", hideComps)
                // let allRelatedComps = this.getAllRelatedComps()?.map(e => e.field)
                // console.log("[handleChooseValue]allRelatedComps:", allRelatedComps)
                // for(let hideComp of hideComps) {
                //     // console.log(hideComp.field, allRelatedComps.includes(hideComp.field))
                //     if(!allRelatedComps.includes(hideComp.field)) {
                //         hideComp.show = true
                //         // console.log(hideComp.show)
                //     }
                // }
            }
        },
        // 获取被关联的全部组件
        getAllRelatedComps() {
            return this.drawList
                .filter((e) => e.relatedComps?.length > 0)
                ?.map((e) => e.relatedComps)
                ?.flat(Infinity);
        },
        // 点击说明文字进行跳转或弹窗
        handlerExplainLink(comp) {
            console.log(
                "[handlerExplainLink]",
                comp.hyperlinks,
                comp.linkAddress,
            );
            if (comp.hyperlinks) {
                window.open(comp.linkAddress, "_blank");
            }

            if (comp.popUpWindow) {
                this.iframeDialogObj.iframeSrc = comp.popUpWindowAddress;
                this.iframeDialogObj.dialogVisible = true;
            }
        },
        // 表单组添加一组子组件
        compsGroupAddGroup(comp) {
            comp.list.push(this.cloneDeep(comp.subComps));
            this.handleSubCompsShowState(comp);
        },
        // 表单组删除最后一组子组件
        compsGroupDelGroup(comp) {
            if (comp.list.length == 1) {
                this.$message.warning("至少保留一条");
                return;
            }
            this.$confirm("确认删除最后一条?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    // 确认
                    comp.list.pop();
                })
                .catch(() => {
                    // 取消
                });
        },
        // 数据校验
        validator(list) {
            for (let comp of list) {
                if (
                    comp.show &&
                    Object.keys(comp).includes("value") &&
                    compsStoreVal.includes(comp.type)
                ) {
                    // 校验必填
                    if (comp.required && comp.value?.length == 0) {
                        this.$message.warning(`请填写${comp.label}`);
                        return false;
                    }
                    // 校验规则
                    if (
                        Object.keys(comp).includes("rules") &&
                        comp.value?.length > 0
                    ) {
                        // 数字校验
                        if (comp.rules == "number") {
                            if (!/^\d+$|^\d*\.\d+$/g.test(comp.value)) {
                                this.$message.warning(
                                    comp.label + "项只能输入数字和小数",
                                );
                                return false;
                            }
                        }
                        // 手机号校验
                        if (comp.rules == "phone") {
                            if (
                                !/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(comp.value)
                            ) {
                                this.$message.warning(
                                    comp.label + "项请输入正确的手机号",
                                );
                                return false;
                            }
                        }
                        // 证件号校验
                        if (comp.rules == "document") {
                            // 身份证号校验
                            if (comp.documentValue == "idCardNo") {
                                if (!this.checkIDCard(comp.value)) {
                                    this.$message.warning(
                                        comp.label + "项请输入正确的身份证号",
                                    );
                                    return false;
                                }
                            }
                            // 中国护照
                            if (comp.documentValue == "passportChina") {
                                if (
                                    !/^([a-zA-z]|[0-9]){5,17}$/.test(comp.value)
                                ) {
                                    this.$message.warning(
                                        comp.label + "项请输入正确的护照号码",
                                    );
                                    return false;
                                }
                            }
                            // 港澳身份证
                            if (
                                ["idCardXianggang", "idCardAomen"].includes(
                                    comp.documentValue,
                                )
                            ) {
                                if (
                                    !/^([A-Z]\d{6,10}(\(\w{1}\))?)$/.test(
                                        comp.value,
                                    )
                                ) {
                                    this.$message.warning(
                                        comp.label +
                                            "项请输入正确的港澳身份证号",
                                    );
                                    return false;
                                }
                            }
                            // 台湾身份证
                            if (comp.documentValue === "idCardTaiwan") {
                                if (
                                    !/^\d{8}|^[a-zA-Z0-9]{10}|^\d{18}$/.test(
                                        comp.value,
                                    )
                                ) {
                                    this.$message.warning(
                                        comp.label +
                                            "项请输入正确的台湾身份证号",
                                    );
                                    return false;
                                }
                            }
                        }
                    }
                }
            }
            return true;
        },
        // 校验身份证
        checkIDCard(idcode) {
            /*
                第一位不可能是0
                第二位到第六位可以是0-9
                第七位到第十位是年份，所以七八位为19或者20
                十一位和十二位是月份，这两位是01-12之间的数值
                十三位和十四位是日期，是从01-31之间的数值
                十五，十六，十七都是数字0-9
                十八位可能是数字0-9，也可能是X
            */
            let idcard_patter =
                /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1|2][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X]|x)$/;
            let format = idcard_patter.test(idcode);
            if (format) {
                const city = {
                    11: "北京",
                    12: "天津",
                    13: "河北",
                    14: "山西",
                    15: "内蒙古",
                    21: "辽宁",
                    22: "吉林",
                    23: "黑龙江",
                    31: "上海",
                    32: "江苏",
                    33: "浙江",
                    34: "安徽",
                    35: "福建",
                    36: "江西",
                    37: "山东",
                    41: "河南",
                    42: "湖北",
                    43: "湖南",
                    44: "广东",
                    45: "广西",
                    46: "海南",
                    50: "重庆",
                    51: "四川",
                    52: "贵州",
                    53: "云南",
                    54: "西藏",
                    61: "陕西",
                    62: "甘肃",
                    63: "青海",
                    64: "宁夏",
                    65: "新疆",
                    71: "台湾",
                    81: "香港",
                    82: "澳门",
                    91: "国外",
                };
                let pass = true; // 验证失败返回false
                if (
                    !idcode ||
                    !/^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i.test(
                        idcode,
                    )
                ) {
                    pass = false;
                } else if (!city[idcode.substr(0, 2)]) {
                    pass = false;
                } else if (idcode.length === 18) {
                    // 18位身份证需要验证最后一位校验位
                    idcode = idcode.split("");
                    // ∑(ai×Wi)(mod 11)
                    // 加权因子
                    const factor = [
                        7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2,
                    ];
                    // 校验位
                    const parity = [1, 0, "X", 9, 8, 7, 6, 5, 4, 3, 2];
                    let sum = 0;
                    let ai = 0;
                    let wi = 0;
                    for (let i = 0; i < 17; i++) {
                        ai = idcode[i];
                        wi = factor[i];
                        sum += ai * wi;
                    }
                    if (
                        String(parity[sum % 11]) !==
                        String(idcode[17].toUpperCase())
                    ) {
                        pass = false;
                    }
                }
                return pass;
            } else {
                return format;
            }
        },
        // 保存
        async submit() {
            console.log("[保存]this.drawList:", this.drawList);
            this.loading = true;
            // 将组件数据结构处理成调接口需要的数据结构
            let allCompsArr = [];
            let drawList = this.cloneDeep(this.drawList);
            let compsArr = drawList.filter((e) => e.type != "compsGroup");
            if (compsArr.length > 0) {
                allCompsArr.push(compsArr);
            }
            let compsGroupList = drawList.filter((e) => e.type == "compsGroup");
            // console.log("[保存]compsGroupList:", compsGroupList)
            for (let compsGroup of compsGroupList) {
                for (let subCompsArr of compsGroup.list) {
                    // console.log(subComps)
                    allCompsArr.push(subCompsArr);
                }
            }
            console.log("[保存]allCompsArr:", allCompsArr);
            // return
            if (allCompsArr.length == 0 || allCompsArr[0]?.length == 0) {
                this.loading = false;
                this.$message.warning("表单无内容");
                return;
            }
            // 根据处理好数据结构的组件数据生成调接口需要的数据
            let paramsArr = [];
            for (let arr of allCompsArr) {
                // 数据校验
                if (!this.validator(arr)) {
                    this.loading = false;
                    return;
                }
                let params = [];
                for (let comp of arr) {
                    if (Object.keys(comp).includes("value")) {
                        if (comp.show === false) {
                            comp.value = this.emptyVal(comp);
                        }
                        let item = {
                            schoolId: this.schoolId,
                            peopleId: this.stuBasicInfo.id,
                            archivesId: this.templateInfo.id,
                            id: comp.valueId, // 值的id，修改时使用
                            designId: comp.id, // 组件id
                            compsGroupId: comp.compsGroupId,
                            groupId: comp.groupId,
                            field: comp.field,
                            archivesPersonType:
                                this.$route.name === "SummaryTeacher"
                                    ? "2"
                                    : "1",
                            assemblyValue: this.handleAssemblyValue(comp),
                            tableValue: this.handleTableValue(comp),
                        };
                        // 如果是校验规则为证件号的单行文本，额外保存证件类型选中项
                        if (comp.type == "input" && comp.rules == "document") {
                            item.supplementValue = comp.documentValue;
                        }
                        params.push(item);
                    }
                }
                // console.log("[保存]传参：", params)
                paramsArr.push(params);
            }
            console.log("[保存]传参：", paramsArr);
            // this.loading = false; return
            let res = null;
            if (this.templateInfo.updateType == "1") {
                // 累加型
                const blob = new Blob([JSON.stringify(paramsArr[0])], {
                    type: "application/json",
                });
                const formData = new FormData();

                formData.append("file", blob);
                res = await this._fet(
                    "/school/schoolArchivesData/saveOrUpdateAll",
                    formData,
                    {isArrParams: true},
                );
                console.log("[保存][单条保存]接口返回：", res);
                this.handleRes(res, () => {
                    this.$message.success("保存成功");
                    this.$emit("submit", paramsArr);
                    this.$eventDispatch("getTeacherBaseInfo");
                });
            } else if (this.templateInfo.updateType == "2") {
                // 更新型
                const blob = new Blob([JSON.stringify(paramsArr)], {
                    type: "application/json",
                });
                const formData = new FormData();

                formData.append("file", blob);

                res = await this._fet(
                    "/school/schoolArchivesData/saveOrUpdateList",
                    formData,
                    {isArrParams: true},
                );
                console.log("[保存][整体保存]接口返回：", res);
                this.handleRes(res, () => {
                    this.$message.success("保存成功");
                    this.init();
                    console.log(this.$route.name, "this.$route.name");
                    this.$eventDispatch("getTeacherBaseInfo");
                });
            }
            this.loading = false;
        },
        emptyVal(comp) {
            if (compsValObjArr.includes(comp.type)) {
                if (comp.type == "select" && !comp.multiple) {
                    return "";
                } else {
                    return [];
                }
            } else {
                return "";
            }
        },
        handleAssemblyValue(comp) {
            if (compsValObjArr.includes(comp.type)) {
                if (comp.type == "select" && !comp.multiple) {
                    return comp.value;
                } else {
                    return JSON.stringify(comp.value);
                }
            } else {
                return comp.value;
            }
        },
        handleTableValue(comp) {
            switch (true) {
                case ["select", "checkbox"].includes(comp.type): // 下拉选择、多选框组
                    if (comp.type == "select" && !comp.multiple) {
                        let label = "";
                        comp.options.map((item) => {
                            if (item.value == comp.value) {
                                label = item.label;
                            }
                        });
                        return label;
                    } else {
                        let val = [];
                        for (let value of comp.value) {
                            let label = comp.options.find(
                                (e) => e.value == value,
                            )?.label;
                            if (label) {
                                val.push(label);
                            } else {
                                console.warn(
                                    `[handleTableValue]${comp.label}选中值${value}未找到对应label`,
                                    comp,
                                );
                                val.push(value);
                            }
                        }
                        return val.join(",");
                    }
                case comp.type == "radio": // 单选
                    let label = comp.options.find(
                        (e) => e.value == comp.value,
                    )?.label;
                    if (label) {
                        return label;
                    } else {
                        console.warn(
                            `[handleTableValue]${comp.label}选中值${comp.value}未找到对应label`,
                            comp,
                        );
                        return comp.value;
                    }
                case comp.type == "miupload": // 多图片上传
                    return comp.value.join(",");
                case comp.type == "fileload": // 附件上传
                    return comp.value.map((e) => e.name).join(",");
                case comp.type == "address": // 地址选择
                    let val = [];
                    for (let value of comp.value) {
                        val.push(value.split("-")[1]);
                    }
                    return val.join(" ");
                default:
                    return comp.value;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.stu-info-form {
    display: flex;
    flex-direction: column;
}

.form-div {
    margin: 50px auto 16px;
    width: 100%;
}
.form-div-view {
    width: unset !important;
    margin: 0;
}
// 字段说明
::v-deep .explainText {
    // position: absolute;
    // bottom: -25px;
    // left: 0px;
    font-size: 12px;
    color: #dd6161;
}
// 组件-分割线
.divider {
    margin: 20px 0 40px;
    position: relative;
    border-top: 1px solid #dcdfe6;

    ::v-deep .divider-title {
        border: unset;
        background: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 0 20px;
        font-size: 16px;
    }
}

/* 组件-单/多图片上传 */
::v-deep .upload-img {
    .el-form-item__label {
        height: 100px;
        line-height: 100px;
    }

    .img-uploader {
        flex: 1;

        .el-upload {
            width: 100px;
            height: 100px;
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            box-sizing: border-box;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            text-align: center;
            line-height: 100px;
            font-size: 28px;
            color: #8c939d;

            .uploaded-img {
                width: 100%;
                height: 100%;
                display: inline-block;
            }
        }

        .el-upload:hover {
            border-color: #409eff;
        }

        .el-upload-list__item {
            width: 100px;
            height: 100px;
        }
    }
}
/* 多图片上传数量足够隐藏上传按钮 */
::v-deep .miupload-full .el-upload--picture-card {
    display: none;
}

// 表单组
.comps-group-div {
    // border: 1px dashed #c7c7cc;
    // border-radius: 4px;
    // box-sizing: border-box;
    // padding: 22px 22px 0;
    margin-bottom: 22px;
}

/* 保存按钮 */
.bottom-btn {
    width: 960px;
    height: 54px;
    background: #ffffff;
    box-shadow: 0px -1px 8px 0px rgba(0, 0, 0, 0.1);
    position: absolute;
    bottom: -16px;
    margin-left: -42px;
    text-align: center;
    line-height: 54px;
    z-index: 10;
}

// 累加型-新增/编辑弹窗
.table-dialog-form {
    .form-div {
        margin-top: 0;
    }

    .bottom-btn {
        width: 100%;
        box-shadow: unset;
        position: static;
        text-align: right;
    }
}

// 禁用状态下样式调整
::v-deep .el-input.is-disabled .el-input__inner, // 单行文本
::v-deep .el-textarea.is-disabled .el-textarea__inner, // 多行文本
::v-deep .el-radio__input.is-disabled+span.el-radio__label, // 单选框组
::v-deep .el-checkbox__input.is-disabled+span.el-checkbox__label, // 多选框组
::v-deep .el-select__tags-text // 下拉选择
{
    color: #000000;
}
// 单选框组选中
::v-deep .el-radio__input.is-disabled.is-checked .el-radio__inner::after {
    background-color: #000000;
}
// 多选框组选中
::v-deep .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
    border-color: #000000;
}
</style>
